import React from 'react'
import ParticleField from 'react-particles-webgl';


export default function Animation() {

    const config = {
        showCube: false,
        dimension: '3D',
        velocity: 0.8,
        boundaryType: 'bounce',
        antialias: false,
        direction: {
          xMin: -1,
          xMax: 1,
          yMin: -1,
          yMax: 1,
          zMin: -1,
          zMax: 1
        },
        lines: {
          colorMode: 'solid',
          color: '#5865F2',
          transparency: 0.9,
          limitConnections: true,
          maxConnections: 20,
          minDistance: 150,
          visible: true
        },
        particles: {
          colorMode: 'solid',
          color: '#5865F2',
          transparency: 0.9,
          shape: 'circle',
          boundingBox: 'cube',
          count: 125,
          minSize: 10,
          maxSize: 79,
          visible: true
        },
        cameraControls: {
          enabled: true,
          enableDamping: true,
          dampingFactor: 0.2,
          enableZoom: false,
          autoRotate: true,
          autoRotateSpeed: 0.3,
          resetCameraFlag: false
        }
      }
      
    return (
        <div style={{ height: "60vh", width: "100%" }}>
            <ParticleField 
                config={config}
            />
        </div>    
    )
}
