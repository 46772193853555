import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import Animation from '../components/elements/animation'

import Illustration from '../icons/illustration.svg'
import Down from '../icons/arrow-down.svg'
import Wave from '../icons/waves.svg'

const IndexPage = () => (
  <>
  <Layout>
    <Seo title="Home" />
    <section 
      id="home"
      data-sal="fade"
      data-sal-easing="ease"      
      data-sal-duration="800"
    >
      <Container>
        <Row>
          <Col lg={6} md={12} className="flex flex-col justify-center">
            <h1>Customized <span className="text-green-300">wealth management</span> &amp; algorithmic trading</h1>
            <p className="mb-10">At Northern Blue Cave, we focus on digital asset markets; building the future of finance with innovation and sophistication.</p>
            <a href="#what"><Down /></a>
          </Col>
          <Col lg={6} md={12} className="page-svg">
            <Hidden xs sm md>
              <Animation />
            </Hidden>
          </Col>
        </Row>
      </Container>
    </section>
    <section 
      id="what"
      data-sal-duration="800"
      data-sal="fade"
      data-sal-easing="ease"
    >
      <Container>
        <Row className="flex-col-reverse md:flex-row-reverse">
          <Col lg={6} md={12} className="flex flex-col justify-center">
            <h2>You can rest easy knowing that we will <span className="text-green-300">exceed your investment needs</span></h2>
            <p>Accessing globally-respected resources and providing boutique style portfolio management. We go hard – investing in innovations in blockchain and cryptocurrency systems and projects that get results. We are a leading edge firm; using tomorrow’s technology combined with proven best practices.</p>
          </Col>
          <Col lg={6} md={12} className="page-svg">
            <div className="svg-wrapper">
              <Illustration />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <div id="contact" className="w-full flex justify-center">
    </div>
  </Layout>
  <section 
    id="contact" 
    className="wave"
  >
    <Wave />
  </section>
  </>
)

export default IndexPage